import React from "react";
import { LoadingState } from "./LoadingState";
import { SupportedState } from "./SupportedState";
import { UnsupportedState } from "./UnsupportedState";
import { OS } from "../../System/System";
import { isSupported } from "../utils/utils";
import { useClientApiContext } from "../../Extension/ClientApiContext";

export interface ContentProps {
  os: OS | undefined;
  browser: any;
  transaction: any;
}

export const Content: React.FC<ContentProps> = ({
  os,
  browser,
  transaction,
}) => {
  const { checking, silent } = useClientApiContext();
  if (!os || (checking && !silent)) {
    return <LoadingState />;
  }
  if (!isSupported(os, browser)) {
    return <UnsupportedState os={os} browser={browser} />;
  }
  return <SupportedState os={os} browser={browser} transaction={transaction} />;
};

import { useCallback } from "react";
import { Transaction } from "../../API/XFA_DEVICE_API";
import { TokenStatus } from "../../System/System";
import { useClientApiContext } from "../../Extension/ClientApiContext";
import { useTranslation } from "react-i18next";

export const useCheckAffiliationConditions = () => {
  const { clientApi, isClientAvailable } = useClientApiContext();
  const { t } = useTranslation();

  return useCallback(
    async (
      isInstalled: boolean | undefined,
      devicePreviouslyAffiliated: boolean | undefined,
      transaction: Transaction | undefined,
      setDevicePreviouslyAffiliated: (affiliated: boolean) => void,
      setTokenStatus: (status: TokenStatus) => void,
      setError: (message: string) => void
    ) => {
      // Only do it once (while devicePreviouslyAffiliated === undefined)
      if (devicePreviouslyAffiliated !== undefined) {
        return;
      }

      // Ensure transaction is present
      if (!transaction) {
        return;
      }

      // Only handle when transaction is still pending
      if (transaction.status !== "PENDING") {
        return;
      }

      // Only check when extension is installed
      if (!isClientAvailable || !isInstalled) {
        return;
      }

      // Only check if there is a requirement to be affiliated
      if (
        transaction?.decisions?.affiliated &&
        transaction?.organization?.organizationId
      ) {
        try {
          clientApi
            .checkDeviceAffiliatedWithOrganization(
              transaction.organization.organizationId
            )
            .then((affiliated) => {
              console.log("Device is affiliated with organization", affiliated);
              setDevicePreviouslyAffiliated(affiliated === true);
            })
            .catch((error) => {
              console.error("Error checking affiliation", error);
              setDevicePreviouslyAffiliated(false);
              setTokenStatus("Error");
              setError(t("Token.errors.affiliation"));
            });
        } catch (error) {
          console.error("Error checking affiliation", error);
          setDevicePreviouslyAffiliated(false);
          setTokenStatus("Error");
          setError(t("Token.errors.affiliation"));
        }
      }
    },
    [clientApi, t, isClientAvailable]
  );
};

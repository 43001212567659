import React from "react";
import { Typography } from "@mui/material";
import {
  OS,
  Browser,
  supportedBrowsers,
  supportedMobileOSes,
  supportedDesktopOSes,
} from "../../System/System";
import NotSupportedBrowserSection from "./NotSupportedBrowserSection";
import NotSupportedOSSection from "./NotSupportedOSSection";

interface UnsupportedStateProps {
  os: OS | undefined;
  browser: Browser | undefined;
}

export const UnsupportedState: React.FC<UnsupportedStateProps> = ({
  os,
  browser,
}) => {
  if (!os) {
    return <Typography>No operating system detected.</Typography>;
  }

  if (!browser) {
    return <Typography>No browser detected.</Typography>;
  }

  const supportedOS =
    os &&
    (supportedDesktopOSes.includes(os) || supportedMobileOSes.includes(os));
  const supportedBrowser =
    (browser && supportedBrowsers.includes(browser)) ||
    (os && supportedMobileOSes.includes(os));

  if (!supportedOS) {
    return (
      <NotSupportedOSSection
        os={os}
        supportedBrowser={supportedBrowser}
        browser={browser}
      />
    );
  }

  if (!supportedBrowser) {
    return <NotSupportedBrowserSection browser={browser} />;
  }

  return <Typography>Unsupported system configuration.</Typography>;
};

import React from "react";
import { goToTokenTransaction } from "../../Navigation/Navigation";

export const useTransactionStatus = (transaction: any, navigate: any) => {
  React.useEffect(() => {
    if (transaction?.status === "COMPLETED") {
      goToTokenTransaction(navigate);
    }
  }, [transaction?.status, navigate]);
};

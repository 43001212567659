import React from "react";
import { OS, Browser, checkOS, checkBrowser } from "../../System/System";

export const useSystemDetection = (navigator: any) => {
  const [os, setOS] = React.useState<OS | undefined>();
  const [browser, setBrowser] = React.useState<Browser | undefined>();
  React.useEffect(() => {
    setOS(checkOS(navigator));
    setBrowser(checkBrowser(navigator));
  }, [navigator]);
  return { os, browser };
};

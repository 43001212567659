import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { detectIncognito } from "detectincognitojs";

export const useSupportedStateLogic = () => {
  const [isPrivate, setIsPrivate] = useState<boolean | undefined>(undefined);
  const [invited, setInvited] = useState<boolean | undefined>(undefined);
  const [ongoingTransaction, setOngoingTransaction] = useState<
    boolean | undefined
  >(undefined);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    detectIncognito().then((result) => setIsPrivate(result.isPrivate));
  }, []);

  useEffect(() => {
    setInvited(searchParams.has("invited"));
    setOngoingTransaction(searchParams.has("transactionId"));
  }, [searchParams]);

  return { isPrivate, invited, ongoingTransaction };
};

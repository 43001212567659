import { DeviceApi, Transaction } from "../../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../../Config";
import { TokenStatus } from "../../System/System";
import { unsupportedTransaction } from "./transactionHelpers";

export const handleUnsupportedTransaction = (
  transaction: Transaction,
  setTokenStatus: (status: TokenStatus) => void,
  setError: (message: string) => void,
  t: (key: string) => string,
  endTransaction: (transaction: Transaction) => void
) => {
  const deviceClient = new DeviceApi(deviceApiConfig);
  unsupportedTransaction(deviceClient, transaction.transactionId)
    .then((transaction) => {
      console.log(`unsupported transaction: ${JSON.stringify(transaction)}`);
      endTransaction(transaction);
    })
    .catch((error) => {
      console.error("unsupported transaction:" + error);
      setError(t("Token.errors.unsupported"));
      setTokenStatus("Error");
    });
};

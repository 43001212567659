import {
  OS,
  Browser,
  supportedDesktopOSes,
  supportedMobileOSes,
  supportedBrowsers,
} from "../../System/System";

export const isSupported = (
  os: OS | undefined,
  browser: Browser | undefined
): boolean => {
  if (!os || !browser) {
    return false;
  }

  const osSupported =
    supportedDesktopOSes.includes(os) || supportedMobileOSes.includes(os);

  const browserSupported =
    supportedBrowsers.includes(browser) || supportedMobileOSes.includes(os);

  return osSupported && browserSupported;
};

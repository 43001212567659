import { useCallback } from "react";
import { Transaction } from "../../API/XFA_DEVICE_API";
import { TokenStatus } from "../../System/System";
import {
  redirectToApplication,
  wipeTransactionID,
} from "../utils/transactionHelpers";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useEndTransaction = (
  setTokenStatus: (status: TokenStatus) => void,
  setMfa: (mfa: boolean) => void
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return useCallback(
    (transaction: Transaction) => {
      if (!transaction) {
        throw new Error("TransactionId not set");
      }

      if (transaction.application) {
        redirectToApplication(
          transaction,
          wipeTransactionID,
          navigate,
          setTokenStatus,
          setMfa,
          searchParams
        );
        return;
      }

      if (transaction.organization) {
        setTokenStatus("OK");
        wipeTransactionID();
      }
    },
    [setTokenStatus, navigate, setMfa, searchParams]
  );
};

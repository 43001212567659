import React from "react";
import {
  OS,
  Browser,
  playStoreLink,
  supportedIOSes,
  appStoreLink,
} from "../../System/System";
import { ConnectSection } from "./ConnectSection";
import ExtensionInstalledSection from "./ExtensionInstalledSection";
import InstallInstructionsSection from "./InstallInstructionSection";
import { useClientApiContext } from "../../Extension/ClientApiContext";
import { setupDeferredDeepLinkForLatestInvitation } from "../../Deeplinks/Deeplinks";
import { useSupportedStateLogic } from "../hooks/useSupportedStateLogic";

interface SupportedStateProps {
  os: OS;
  browser: Browser;
  transaction: any;
}

export const SupportedState: React.FC<SupportedStateProps> = ({
  os,
  browser,
  transaction,
}) => {
  const { isClientAvailable, isNativeClientAvailable } = useClientApiContext();
  const { isPrivate, invited, ongoingTransaction } = useSupportedStateLogic();

  if (["Windows", "macOS"].includes(os)) {
    // Desktop flow
    return (
      <>
        {isClientAvailable || isNativeClientAvailable ? (
          <ExtensionInstalledSection
            browser={browser}
            ongoingTransaction={ongoingTransaction}
          />
        ) : (
          <InstallInstructionsSection
            invited={invited}
            browser={browser ?? ""}
            isPrivate={isPrivate}
            os={os}
          />
        )}
      </>
    );
  }

  if (os === "Android") {
    // Mobile flow for Android
    return (
      <ConnectSection
        goToInstallation={async () => {
          await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interaction
          console.debug("testing:redirectedToIOSAppStore");
          window.open(playStoreLink, "_self");
        }}
        organization={transaction?.organization?.name}
        organizationIconUrl={transaction?.organization?.logoUrl}
        mobile={true}
        invited={invited ?? false}
        isSkippable={undefined}
      />
    );
  }

  if (supportedIOSes.includes(os)) {
    // Mobile flow for iOS
    return (
      <ConnectSection
        goToInstallation={async () => {
          await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interaction
          console.debug("testing:redirectedToIOSAppStore");
          window.open(appStoreLink, "_self");
        }}
        organization={transaction?.organization?.name}
        organizationIconUrl={transaction?.organization?.logoUrl}
        mobile={true}
        invited={invited ?? false}
        isSkippable={undefined}
      />
    );
  }

  return null;
};

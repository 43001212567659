import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import AppIcon from "../../../images/General_XFA_app_icon.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RecheckIcon from "../../../images/recheck-icon.svg";
import WarningIcon from "../../../images/warning-circle-red-icon.svg";
import CheckedIcon from "../../../images/performed_check.png";
import { OS } from "../../System/System";
import "../../General/general.css";
import { useClientApiContext } from "../../Extension/ClientApiContext";
import { LoadingState } from "./LoadingState";

interface InstallInstructionsSectionProps {
  invited: boolean | undefined;
  browser: any;
  isPrivate: boolean | undefined;
  os: OS;
}

type StepWithoutImage = {
  type: "text";
  label: string;
};

type StepWithImage = {
  type: "image";
  label: string;
  image: string;
};

type StepType = StepWithoutImage | StepWithImage;
export type BrowserType = "Chrome" | "Firefox" | "Edge";

const InstallInstructionsSection: React.FC<InstallInstructionsSectionProps> = ({
  invited,
  browser,
  isPrivate,
  os,
}) => {
  const { t } = useTranslation();
  const { checking, isNativeClientAvailable, recheck, isRechecking } =
    useClientApiContext();
  const [isPrivateDialogOpen, setIsPrivateDialogOpen] = useState(false);
  const [didRecheck, setDidRecheck] = useState(
    new URLSearchParams(window.location.search).get("recheck") === "true"
  );
  const [recheckTimerStarted, setRecheckTimerStarted] = useState(false);
  const recheckElapsed =
    Number(new URLSearchParams(window.location.search).get("recheckElapsed")) ??
    0;
  let url: string | undefined = undefined;

  if (os === "macOS") {
    url =
      "https://distribution.xfa.tech/xfa-native-desktop-application/XFA.pkg";
  } else if (os === "Windows") {
    url =
      "https://distribution.xfa.tech/xfa-native-desktop-application/XFA.msi";
  }

  useEffect(() => {
    if (didRecheck) {
      setRecheckTimerStarted(true);
      const interval = setInterval(() => {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set(
          "recheckElapsed",
          (
            (Number(
              new URLSearchParams(window.location.search).get("recheckElapsed")
            ) || 0) + 1
          ).toString()
        );
        window.history.replaceState(null, "", newUrl.toString());
        if (recheckElapsed >= 3) {
          clearInterval(interval);
        } else {
          recheck(false, true);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [didRecheck, recheck, recheckElapsed]);

  const browserDetails: Record<
    BrowserType,
    { storeLink: string; steps: StepType[]; privateSteps?: StepType[] }
  > = {
    Chrome: {
      storeLink: url!,
      steps: [
        { type: "text", label: t("Installation.nativeClient.step1") },
        { type: "text", label: t("Installation.nativeClient.step2") },
        { type: "text", label: t("Installation.nativeClient.step3") },
      ],
    },
    Firefox: {
      storeLink: url!,
      steps: [
        { type: "text", label: t("Installation.nativeClient.step1") },
        { type: "text", label: t("Installation.nativeClient.step2") },
        { type: "text", label: t("Installation.nativeClient.step3") },
      ],
    },
    Edge: {
      storeLink: url!,
      steps: [
        { type: "text", label: t("Installation.nativeClient.step1") },
        { type: "text", label: t("Installation.nativeClient.step2") },
        { type: "text", label: t("Installation.nativeClient.step3") },
      ],
    },
  };

  const { steps, storeLink, privateSteps } =
    browserDetails[browser as BrowserType];

  const renderRecheckButton = (index: number) => {
    if (index !== 2 || checking || isRechecking) return null;

    const icon =
      didRecheck && recheckTimerStarted && recheckElapsed >= 3
        ? isNativeClientAvailable
          ? CheckedIcon
          : WarningIcon
        : RecheckIcon;

    return (
      <Button
        disabled={didRecheck && recheckTimerStarted && recheckElapsed < 3}
        className="recheck-button"
        startIcon={
          <img
            src={icon}
            alt="Recheck"
            className={
              didRecheck && recheckTimerStarted && recheckElapsed < 3
                ? "rotating-icon"
                : ""
            }
          />
        }
        onClick={() => {
          setDidRecheck(true);
          recheck(true);
          const newUrl = new URL(window.location.href);
          newUrl.searchParams.set("recheck", "true");
          newUrl.searchParams.set("recheckElapsed", "0");
          window.history.replaceState(null, "", newUrl.toString());
        }}
      >
        <Typography className="install-text" style={{ flex: 1 }}>
          {didRecheck && recheckTimerStarted && recheckElapsed < 3
            ? t("Installation.nativeClient.rechecking")
            : didRecheck && !isNativeClientAvailable
            ? t("Installation.nativeClient.notInstalled")
            : t("Installation.nativeClient.recheck")}
        </Typography>
      </Button>
    );
  };

  const renderStepContent = (step: StepType, index: number) => {
    if (step.type === "image") {
      return <img alt={step.label} style={{ width: 300 }} src={step.image} />;
    }
    if (index === 0) {
      return (
        <Button
          variant="contained"
          className="black-button"
          onClick={() => window.open(storeLink, "_blank")}
        >
          {t(`Installation.nativeClient.step1Button`)}
        </Button>
      );
    }
    return renderRecheckButton(index);
  };
  if (isRechecking) {
    return <LoadingState />;
  }

  return (
    <>
      <div>
        <Typography className="header">
          {!invited
            ? t("Installation.nativeClient.title")
            : t("Installation.titleInvited")}
        </Typography>
        <Typography className="body1">
          {t("Installation.nativeClient.description")}
        </Typography>
      </div>

      {isPrivate && (browser as BrowserType) === "Edge" && (
        <>
          <h3 className="header">{t("Installation.isPrivate")}</h3>
          <div className="button-container" style={{ width: "100%" }}>
            <Button
              startIcon={
                <img className="smallIcon" src={AppIcon} alt="Ongoing" />
              }
              className="icon-button"
              onClick={() => setIsPrivateDialogOpen(true)}
              endIcon={<ChevronRightIcon />}
              style={{ width: "100%" }}
            >
              <Typography className="install-text" style={{ flex: 1 }}>
                {t("Installation.isPrivateButton")}
              </Typography>
            </Button>
          </div>
        </>
      )}

      <Stepper className="instructions" orientation="vertical">
        {steps.map((step, index) => (
          <Step
            key={index}
            active
            sx={{
              ".MuiStepLabel-label": {
                color: "var(--color-gray-600) !important",
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "19px",
                marginLeft: "32px",
                letterSpacing: "0em",
              },
            }}
          >
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <div className="stepContent">
                {renderStepContent(step, index)}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      <Dialog
        open={isPrivateDialogOpen}
        onClose={() => setIsPrivateDialogOpen(false)}
        maxWidth="md"
      >
        <DialogTitle>
          {t("Installation.installationInstructionsPrivate")}
          <IconButton
            aria-label="close"
            onClick={() => setIsPrivateDialogOpen(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper className="instructions" orientation="vertical">
            {privateSteps?.map((step, index) => (
              <Step key={index} active>
                <StepLabel>{step.label}</StepLabel>
                {step.type === "image" && (
                  <StepContent>
                    <div className="stepContent">
                      <img
                        alt={step.label}
                        style={{ width: 300 }}
                        src={step.image}
                      />
                    </div>
                  </StepContent>
                )}
              </Step>
            ))}
          </Stepper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InstallInstructionsSection;

import Typography from "@mui/material/Typography";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";

export const RedirectingSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="general">
      <Typography className="header">{t("Token.checking")}</Typography>
      <div className="success">
        <CheckCircleIcon className="icon SuccessIcon" />
        <Typography className="body">{t("Token.success")}</Typography>
      </div>
    </div>
  );
};

export interface VerifiedSectionProps {
  organizationName: string;
}

export const VerifiedSection: React.FC<VerifiedSectionProps> = ({
  organizationName,
}) => {
  const { t } = useTranslation();

  return (
    <div className="general">
      <div className="success">
        <Typography className="header header-with-icon">
          {t("Token.verified")}
          <CheckCircleIcon className="icon" />
        </Typography>
      </div>
      <div className="multiple-explainers">
        <Typography className="body">
          {t("Token.verifiedExplainer1", { organization: organizationName })}
        </Typography>
        <Typography className="body">
          {t("Token.verifiedExplainer2", { organization: organizationName })}
        </Typography>
        <Typography className="body">
          {t("Token.verifiedExplainer3")}
        </Typography>
      </div>
    </div>
  );
};

import React from "react";
import { CircularProgress } from "@mui/material";

export const LoadingState: React.FC = () => {
  return (
    <div className="checking">
      <div>
        <CircularProgress className="indicator" />
      </div>
    </div>
  );
};
